import React from 'react';
import '../assets/css/landing.css'; // Import your CSS file

const LandingPage = () => {
  return (
    <div className="container">
      <div className="content">
        <h1>Ovos Vision</h1>
      </div>
      <div id="contact" className="contact-section">
        <h2>Contact Us</h2>
        <p>sales@ovosvision.com</p>
      </div>
    </div>
  );
};

export default LandingPage;
