// Thumbnail.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Thumbnail = ({ folder, desc, coverUrl, onClick }) => (
  <Link to={`/asset/${folder}`} className="grid-item">
    <div className="thumbnail-image">
      <img src={coverUrl} alt="Cover" />
    </div>
    <div className="thumbnail-text">{desc}</div>
  </Link>
);

Thumbnail.propTypes = {
  folder: PropTypes.string.isRequired,
  coverUrl: PropTypes.string.isRequired,
};

export default Thumbnail;
