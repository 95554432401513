import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import MenuItem from './MenuItem';
import { BsCloudUpload } from 'react-icons/bs';
import { GrCatalog } from "react-icons/gr";
import { IoMdPeople } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import UploadModal from './UploadModal';

const MenuButton = ({ showMenu, toggleMenu }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate()

  const handleCatalogClick = () => {
    navigate('/catalog');
    toggleMenu();
  };

  const handleUploadClick = () => {
    navigate('/contribute')
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="menu-button" onClick={toggleMenu}>
      <FaBars size={24} />
      <UploadModal isOpen={isModalOpen} onClose={handleCloseModal} />
      {showMenu && (
        <div className="menu">
          <MenuItem icon={BsCloudUpload} text="FAQ" onClick={handleUploadClick} />
        </div>
      )}
    </div>
  );
};

export default MenuButton;