import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import AssetManagerPage from './components/assetManager/AssetManagerPage';
import LandingPage from './components/LandingPage';
import Catalog from './components/catalog/Catalog';
import SplatViewerWrapper from './components/SplatViewerWrapper';
import MapView from './components/map/MapView';
import ReactGA from 'react-ga';
import Contribute from './components/Contribute';

const App = () => {
  const hostname = window.location.hostname;
  const TRACKING_ID = "G-V2MBT2Y8SR";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  // Check if the hostname starts with 'world.'
  const isWorldDomain = hostname.startsWith('world.');

  return (
    <Router>
      <Routes>
        {isWorldDomain ? (
          <>
            <Route path="/map/:location" element={<MapView />} />
            <Route path="/:assetId?" element={<MapView />} />
          </>
        ) : (
          <Route path="/" element={<LandingPage />} /> // LandingPage for other domains
        )}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/asset-manager" element={<AssetManagerPage />} />
        <Route path="/asset/:datasetName" element={<SplatViewerWrapper />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/contribute" element={<Contribute />} />

      </Routes>
    </Router>
  );
};

export default App;
