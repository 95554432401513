import React from 'react';
import { useParams } from 'react-router-dom';
import SplatViewer from './SpatViewer';

import '../assets/css/splatwrapper.css';

export default function SplatViewerWrapper() {
  const { datasetName } = useParams();

  return (
    <div id="splat-wrapper-container">
      <SplatViewer datasetName={datasetName} />
    </div>
  );
};
