// MenuItem.js
import React from 'react';

const MenuItem = ({ icon: IconComponent, text, onClick }) => {
  return (
    <div className="menu-item" onClick={onClick}>
      <div className="menu-icon">
        <IconComponent size={40} />
      </div>
      <div className="menu-text">{text}</div>
    </div>
  );
};

export default MenuItem;
