import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { mapConfig } from './MapConfig';
import { useParams } from 'react-router-dom';
import S3Fetcher from '../s3Utils/S3Fetcher';
import MenuButton from './Menu'

import assetMetadatas from '../../assets/meta/assets_metadata.json';
import '../../assets/css/map.css';

const MapView = () => {
  const { location, assetId } = useParams();
  const [lat, lng, zoom] = location?.split(',') || [];
  const [assetMetadata, setAssetMetadata] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

  const markerHtml = (asset, imgUrl) => {
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://www.ovos.ai';
    const assetUrl = `${baseUrl}/asset/${encodeURIComponent(asset.asset_name)}`;

    return `<a href="${assetUrl}" target="_blank" class="marker-info-window">
              <div class="marker-thumbnail">
                <img class="thumbnail-img" src="${imgUrl}" alt="${asset.name}" />
                <div class="thumbnail-description">
                  ${asset.name}
                </div>
              </div>
            </a>`;
  };

  useEffect(() => {
    mapboxgl.accessToken = mapboxToken;

    const initializeMap = ({ lat, lng, zoom }) => {
      if (!map.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/pnee/clwef3mlg00fn01po8pywbfov',
          center: [lng, lat],
          pitch: 60,
          zoom: zoom,
        });

        const markers = {};

        assetMetadatas.forEach(async (asset) => {
          const el = document.createElement('div');
          el.className = `marker-${asset.type}`;
          const marker = new mapboxgl.Marker(el)
            .setLngLat([asset.location.lng, asset.location.lat])
            .addTo(map.current);
          
          markers[asset.id] = marker;

          if (assetId && asset.id === assetId) {
            const imageUrl = await fetcher.getCoverUrl(asset.asset_name, 'cover.jpg', 3000);
            const popupContent = markerHtml(asset, imageUrl);
            new mapboxgl.Popup()
              .setLngLat([asset.location.lng, asset.location.lat])
              .setHTML(popupContent)
              .addTo(map.current);
          }

          marker.getElement().addEventListener('click', async () => {
            const imageUrl = await fetcher.getCoverUrl(asset.asset_name, 'cover.jpg', 3000);
            const popupContent = markerHtml(asset, imageUrl);
            new mapboxgl.Popup()
              .setLngLat([asset.location.lng, asset.location.lat])
              .setHTML(popupContent)
              .addTo(map.current)
            const newUrl = `/${asset.id}`;
            window.history.replaceState(null, 'Map', newUrl);
          });
        });

        map.current.on('click', (e) => {
          const { lat, lng } = e.lngLat.wrap();
          const zoom = map.current.getZoom();
          const newUrl = `/map/${lat},${lng},${zoom}`;
          window.history.replaceState(null, 'Map', newUrl);
        });

      } else {
        map.current.setCenter([lng, lat]);
        map.current.setZoom(zoom);
      }
    };

    const fetcher = new S3Fetcher({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_S3_REGION,
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    });

    if (!assetId && lat && lng && zoom) {
      initializeMap({ lat: parseFloat(lat), lng: parseFloat(lng), zoom: parseFloat(zoom) });
    } else if (assetId) {
      const assetMetadata = assetMetadatas.find((metadata) => metadata.id === assetId);
      if (assetMetadata) {
        setAssetMetadata(assetMetadata);
        initializeMap({
          lat: assetMetadata.location.lat,
          lng: assetMetadata.location.lng,
          zoom: 16,
        });
      } else {
        const defaultUrl = `/map/${mapConfig.defaultPosition[0]},${mapConfig.defaultPosition[1]},${mapConfig.defaultZoom}`;
        window.history.replaceState(null, 'Map', defaultUrl);
      }
    } else {
      initializeMap({
        lat: mapConfig.defaultPosition[0],
        lng: mapConfig.defaultPosition[1],
        zoom: mapConfig.defaultZoom,
      });
    }

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, [assetId, lat, lng, zoom, mapboxToken]);

  const toggleMenu = () => {
    setShowMenu(!showMenu); // Toggle the menu state
  };

  return (
    <>
      <div className="map-view-container" style={{ height: '100%', width: '100%' }}>
        <MenuButton showMenu={showMenu} toggleMenu={toggleMenu} />
        <div ref={mapContainer} className="map-container" />
      </div>
    </>
  );
};

export default MapView;
