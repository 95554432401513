import * as AWS from 'aws-sdk';

interface S3FetcherOptions {
  accessKeyId: string;
  secretAccessKey: string;
  region: string;
  bucketName: string;
}

export default class S3Fetcher {
  private s3: AWS.S3;

  constructor(private options: S3FetcherOptions) {
    AWS.config.update({
      accessKeyId: options.accessKeyId,
      secretAccessKey: options.secretAccessKey,
      region: options.region,
    });
    this.s3 = new AWS.S3();
  }

  async getPresignedUrl(datasetName: string, assetName: string, extension: string, expiresIn: number): Promise<string> {
    const key = `${datasetName}/${assetName}.${extension}`;

    const params = {
      Bucket: this.options.bucketName,
      Key: key,
      Expires: expiresIn,
    };

    const signedUrl = await this.s3.getSignedUrlPromise('getObject', params);
    return signedUrl;
  }

  async getMetadata(assetName: string, expiresIn: number): Promise<string> {
    const key = `${assetName}/metadata`;
    const params = {
      Bucket: this.options.bucketName,
      Key: key,
      Expires: expiresIn,
    };

    const signedUrl = await this.s3.getSignedUrlPromise('getObject', params);
    return signedUrl;
  } 

  async getCoverUrl(assetName: string, fileName: string, expiresIn: number): Promise<string> {
    const key = `${assetName}/${fileName}`;

    const params = {
      Bucket: this.options.bucketName,
      Key: key,
      Expires: expiresIn, // Expiration time in seconds
    };

    const signedUrl = await this.s3.getSignedUrlPromise('getObject', params);
    return signedUrl;
  }

  async fetchAsset(assetName: string, file: string): Promise<Buffer | null> {
    const key = `${assetName}/${file}`; // Construct the S3 key
    try {
      const params = {
        Bucket: this.options.bucketName,
        Key: key,
      };

      const data = await this.s3.getObject(params).promise();
      if (data.Body) {
        return data.Body as Buffer; // Return the fetched object as a Buffer
      } else {
        return null; // Return null if the object body is empty
      }
    } catch (error) {
      console.error('Error fetching asset:', error);
      throw error;
    }
  }

  async listFolders(prefix: string): Promise<string[]> {
    try {
      const params: AWS.S3.ListObjectsV2Request = {
        Bucket: this.options.bucketName,
        Prefix: prefix,
        Delimiter: '/',
      };

      const data = await this.s3.listObjectsV2(params).promise();
      if (data.CommonPrefixes) {
        return data.CommonPrefixes.map((folder) => folder.Prefix || '');
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error listing folders:', error);
      throw error;
    }
  }
}
