// UploadModal.js
import React from 'react';

const UploadModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="upload-modal">
      <div className="upload-modal-content">
        <h2>Upload Form</h2>
        {/* Your form fields */}
        <form>
          {/* Form fields go here */}
        </form>
        <button onClick={onClose}>Close Modal</button>
      </div>
    </div>
  );
};

export default UploadModal;
