import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import S3Fetcher from '../s3Utils/S3Fetcher';
import Thumbnail from './Thumbnail';

import '../../assets/css/catalog.css';

const Catalog = () => {
  const [thumbnailsMetadata, setThumbnailsMetadata] = useState([]);

  useEffect(() => {
    console.log('Fetching metadata...');
    const fetchMetadata = async () => {
      try {
        const fetcher = new S3Fetcher({
          accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
          region: process.env.REACT_APP_S3_REGION,
          bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        });

        // Get the metadata JSON file using S3Fetcher
        const metadataUrl = await fetcher.getPresignedUrl('metadata', 'catalog', 'json', 3000); // 3000 seconds expiry
        const response = await fetch(metadataUrl);
        const m = await response.json();

        // Create an array of promises to fetch cover URLs
        const coverUrlPromises = m.map(async item => {
          const coverUrl = await fetcher.getCoverUrl(item.folder, 'cover.jpg', 3000); // Fetch cover URL using S3Fetcher
          return { folder: item.folder, desc: item.description, coverUrl };
        });

        // Wait for all cover URLs to be fetched
        const thumbnailsData = await Promise.all(coverUrlPromises);
        setThumbnailsMetadata(thumbnailsData);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetadata();
  }, []);

  const handleThumbnailClick = (thumbnail) => {
    console.log('Thumbnail clicked:', thumbnail);
  }

  return (
    <div className="catalog">
      <div className="catalog-header">
        <Link to="https://world.ovos.ai">
          <img src="/apple-touch-icon.png" alt="Ovos Vision" />
        </Link>
      </div>
      <div className="catalog-grid-container">
        {thumbnailsMetadata.map((thumbnail, index) => (
          <Thumbnail
            key={index}
            folder={thumbnail.folder}
            desc={thumbnail.desc}
            coverUrl={thumbnail.coverUrl}
            onClick={() => handleThumbnailClick(thumbnail)}
          />
        ))}
      </div>
    </div>
  );
};

export default Catalog;
