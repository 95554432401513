import React, { useState, useEffect, useRef } from 'react';
import S3Fetcher from './s3Utils/S3Fetcher';
import * as GaussianSplats3D from '@mkkellogg/gaussian-splats-3d';
import ReactGA from 'react-ga';
import * as THREE from 'three';
import { Link } from 'react-router-dom';

import '../assets/css/gs.css';

const SplatViewer = ({ datasetName }) => {
  const [metadata, setMetadata] = useState(null);
  const [assetUrl, setAssetUrl] = useState(null);
  const [showCreditFooter, setShowCreditFooter] = useState(false);
  const [progress, setProgress] = useState(0);
  const [gradientColors, setGradientColors] = useState(['black', 'black', 'black']);
  const [cameraDistance, setCameraDistance] = useState([0.5, 1.5]);
  const [maxPolar, setMaxPolar] = useState(Math.PI * .50);
  const [azimuthRange, setAzimuthRange] = useState(null);

  const [angles, setAngles] = useState([0, 0, 0]);

  const viewerContainerRef = useRef(null);
  const fetcher = new S3Fetcher({
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_S3_REGION,
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  });

  useEffect(() => {
    async function getMetadata() {
      try {
        const metadataUrl = await fetcher.getMetadata(datasetName, 1000);
        fetch(metadataUrl)
          .then(response => response.json())
          .then(data => {
            setMetadata(data);
            if (data.credit) setShowCreditFooter(true);
            if (data.gradientColors) setGradientColors(data.gradientColors);
            if (data.cameraRange) setCameraDistance(data.cameraRange);
            if (data.maxPolar) setMaxPolar(data.maxPolar);
            if (data.azimuthRange) setAzimuthRange(data.azimuthRange);
          });
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    }
    getMetadata();
  }, []);

  useEffect(() => {
    const getUrl = async () => {
      if (metadata) {
        try {
          const url = await fetcher.getPresignedUrl(datasetName, metadata.assetName, metadata.assetType);
          setAssetUrl(url);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    getUrl();
  }, [metadata, datasetName]);

  useEffect(() => {
    const initViewer = async () => {
      if (assetUrl && metadata && viewerContainerRef.current) {
        const viewer = new GaussianSplats3D.Viewer({
          sharedMemoryForWorkers: false,
          rootElement: viewerContainerRef.current,
          focalAdjustment: 3.0,
        });

        const assetFormat = metadata.assetType === 'ksplat' ? 1 : metadata.assetType === 'ply' ? 2 : 0;
        const alphaRemoveThreshold = metadata.alphaRemoveThreshold ?? 1;

        try {
          await viewer.addSplatScene(assetUrl, {
            format: assetFormat,
            splatAlphaRemovalThreshold: alphaRemoveThreshold,
            streamView: true,
            showLoadingUI: false,
            onProgress: (p, l, s) => setProgress(Math.round(s)),
          });
          viewer.start();
        } catch (error) {
          console.error('Error loading splat scene:', error);
        }

        viewer.controls.enableZoom = true;
        viewer.controls.maxDistance = cameraDistance[1];
        viewer.controls.minDistance = cameraDistance[0];
        if (azimuthRange) {
          viewer.controls.minAzimuthAngle = azimuthRange[0];
          viewer.controls.maxAzimuthAngle = azimuthRange[1];
        }
        viewer.controls.maxPolarAngle = maxPolar;

        const updateCameraDistance = () => {
          setAngles([viewer.controls.getPolarAngle(), viewer.controls.getAzimuthalAngle(), viewer.controls.getDistance()]);
        };

        viewer.controls.addEventListener('change', updateCameraDistance);
        updateCameraDistance();

        viewer.controls.target = new THREE.Vector3().fromArray(metadata.cameraLookAt);
        viewer.camera.position.copy(new THREE.Vector3().fromArray(metadata.cameraPosition));
        viewer.camera.up.copy(new THREE.Vector3().fromArray(metadata.cameraUp)).normalize();
        viewer.camera.lookAt(new THREE.Vector3().fromArray(metadata.cameraLookAt));
        viewer.controls.update();

        ReactGA.event({
          category: 'Splat',
          action: 'View splat',
          label: metadata.assetName,
          nonInteraction: true,
        });
      }
    };
    initViewer();
  }, [assetUrl, metadata, cameraDistance, azimuthRange, maxPolar]);

  const gradientStyle = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundImage: `radial-gradient(circle, ${gradientColors.join(', ')})`,
  };

  return (
    <>
      <div className="parent-splat-viewer" id="viewer-container" ref={viewerContainerRef} style={gradientStyle}></div>
      {progress !== 2 && (
        <div id="progress">
          <div className="spinner"></div>
        </div>
      )}
      <div className="comp-icon">
        <Link to="https://world.ovos.ai">
          <img src="/apple-touch-icon.png" alt="Ovos Vision" />
        </Link>
      </div>
      {showCreditFooter && (
        <div className="splat-viewer-footer">
          <p>Credit to: {metadata.credit}</p>
        </div>
      )}
      {process.env.REACT_APP_IS_DEBUG && (
        <div id="camera-distance" style={{ position: 'absolute', top: '10px', left: '10px', color: 'white' }}>
          <p>Camera Polar: {angles[0]}</p>
          <p>Camera Azimuth: {angles[1]}</p>
          <p>Camera Distance: {angles[2]}</p>
        </div>
      )}
    </>
  );
};

export default SplatViewer;