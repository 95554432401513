import React from 'react';
import '../assets/css/faq.css';

const FAQ = () => {
  return (
    <div className="faq-container">
      <h1 className="faq-title">FAQ</h1>
      <div className="faq-item">
        <p className="faq-question">Q: What is your vision?</p>
        <p className="faq-answer">A: Create a 3D version of google street view.</p>
      </div>
      <div className="faq-item">
        <p className="faq-question">Q: How to contribute?</p>
        <p className="faq-answer">A: Well, you will have to email me the video or .splat file.</p>
      </div>
      <div className="faq-item">
        <p className="faq-question">Q: How to contribute to the repo?</p>
        <p className="faq-answer">
          A: You can just open a PR to <a href="https://github.com/philipnee/asset-manager" className="faq-link">https://github.com/philipnee/asset-manager</a>
        </p>
      </div>
      <div className="faq-item">
        <p className="faq-question">Q: There's a bug I want to report/a feature I want to implement</p>
        <p className="faq-answer">A: File a github issue.</p>
      </div>
      <div className="faq-item">
        <p className="faq-question">Q: The code is horrible</p>
        <p className="faq-answer">A: Submit a patch please.</p>
      </div>
      <div className="faq-item">
        <p className="faq-question">Q: How do you support this project?</p>
        <p className="faq-answer">A: I don't buy lunch so that I can save money to pay AWS to host my stuff.</p>
      </div>
    </div>
  );
};

export default FAQ;
